<template>
  <div>
    <FullScreenDialog
      persistent
      v-model="localOpenDialog"
      :title="title"
      close-button-text=""
      :can-close="false"
    >
      <template v-slot:header-actions>
        <div class="mr-15" v-if="selectedComponent == 'scanner'">
          <v-row>
            <v-text-field
              class="mt-3"
              v-model="barcodeInputKeyboard"
              dense
              prepend-icon="mdi-code-tags"
              label="Barcode"
              hide-details="auto"
            ></v-text-field>
            <v-btn icon text class="mt-3" @click="sendBarcodeInputKeyboard">
              <v-icon>mdi-search-web</v-icon>
            </v-btn>
          </v-row>
        </div>
        <v-btn icon small class="ml-3" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
      <template v-slot:content>
        <div
        v-if="!selectedCategory"
        class="ma-10 d-flex flex-wrap justify-center align-center"
      >
        <ResponsiveCardSelector
          class="section-fade-in"
          :items="typeUpload"
          v-model="selectedType"
          :returnObject="true"
          @click="handlePermission"
          item-key="name"
          card-max-height="400"
          card-min-height="400"
          card-max-width="400"
          card-min-width="400"
          :apply-d-flex="false"
        >
          <template v-slot:item="{ item, cardMinHeight }">
            <div
              class="pa-2 d-flex flex-column justify-center align-center"
              :style="{
                'min-height':
                  cardMinHeight.indexOf('px') != -1
                    ? cardMinHeight
                    : cardMinHeight + 'px',
                'background-color': item.color,
              }"
            >
              <div class="mt-2 font-weight-medium" style="font-size: 150%">
                {{ item.name }}
              </div>
              <v-icon class="ma-5" large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
        <StandardDialog
          v-model="showBarcode"
          title="Scarico Magazzino"
          :dialog-height="null"
          dialog-max-width="500px"
          persistent
        >
          <ManualBarcodeInput
            v-model="barcodePermission"
            @confirm="uploadPermission"
          >
          </ManualBarcodeInput>
        </StandardDialog>
      </div>
      <div>
        <v-row>
          <div class="ml-5 mt-3">
            <v-btn v-if="selectedComponent !== ''" icon @click="backSelection">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div class="mt-5 ml-2 font-italic font-weight-medium">
            {{ toUpperCaseFirstLetter(selectedCategory) }}
          </div>
        </v-row>
      </div>

      <MovementUnloadStepper
        v-if="selectedComponent == 'unloader'"
        :class="{
          'section-fade-in': selectedComponent !== '',
        }"
        :step-focused="stepFocused"
        @selected-file="handleSelectionFile"
        @selection-multiple-item="handleSelectionMultipleItem"
        @back-step="handleBack"
        :items-from-file="itemsFromFile"
        :items-unloaded="itemsUnloaded"
        :items-in-error="itemsInError"
      >
      </MovementUnloadStepper>
      <MovementScannerUnload
        v-else-if="selectedComponent == 'scanner'"
        :class="{
          'section-fade-in': selectedComponent !== '',
        }"
        @selection-items-to-unload="handleSelectionMultipleItem"
        :bus="bus"
      >
      </MovementScannerUnload>
      </template>
      <template v-slot:footer-actions>
        <div v-if="selectedComponent == 'scanner'">
          <v-btn
            color="error"
            :disabled="!canElaborateByScanner"
            @click="unloadFromScanner"
            :loading="movementsLoading"
          >
            Elabora
            <div v-if="counter > 0">({{ counter }})</div>
          </v-btn>
        </div>
        <div v-if="stepFocused == 1 && selectedComponent == 'unloader'">
          <v-btn
            class="right"
            color="error"
            :disabled="canContinue"
            @click="continueToUnload"
          >
            Continua
          </v-btn>
        </div>
        <div v-else-if="stepFocused == 2 && selectedComponent == 'unloader'">
          <v-btn @click="stepFocused = stepFocused - 1" text> Indietro </v-btn>

          <v-btn
            color="error"
            :disabled="!canElaborate || elaborating"
            @click="unloadFromFile"
            :loading="movementsLoading"
          >
            <v-progress-circular
              v-if="elaborating"
              indeterminate
              color="error"
            ></v-progress-circular>
            <div v-else>
              Elabora
              <div v-if="counter > 0">({{ counter }})</div>
            </div>
          </v-btn>
        </div>
        <div v-else-if="stepFocused == 3 && selectedComponent == 'unloader'">
          <v-btn @click="backElaborate" text> Indietro </v-btn>
          <v-btn color="error" @click="goToItemList"> Chiudi </v-btn>
        </div>
      </template>
    </FullScreenDialog>

    <ErrorDialog
      dialog-width="360"
      dialog-height="360"
      persistent
      v-model="showErrorDialog"
      title="Error"
    >
      {{ errorMessage }}
    </ErrorDialog>

    <ConfirmDialog
      v-if="openConfirmDialog"
      :value="openConfirmDialog"
      @close="openConfirmDialog = false"
      dialog-width="360"
      dialog-height="calc(200px-10px)"
      persistent
      title="Scarico Prodotti"
      ><v-alert class="ma-2" dense outlined type="success">
        {{ successMessage }}
      </v-alert>
      <template> </template>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn @click="closeAll" text color="error">Chiudi</v-btn>
        <v-btn @click="goToItemList" text>Lista Movimenti</v-btn>
      </template>
    </ConfirmDialog>
  </div>
</template>


<script>
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";

import FileUploader from "@/components/common/FileUploader.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import ErrorDialog from "@/components/common/StandardDialog.vue";
import ConfirmDialog from "@/components/common/StandardDialog.vue";

import MovementUnloadStepper from "./unloader/MovementUnloadStepper.vue";
import MovementScannerUnload from "./scanner/MovementScannerUnload.vue";

import importerService from "@/services/importer/importer.service";
import movementsService from "@/services/warehouse/movements.service";
import currentUser from "@/services/currentUser.service";
import operatorService from '@/services/operators/operators.service.js';
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import Vue from "vue";

export default {
  name: "MovementUnloadDialog",
  components: {
    ResponsiveCardSelector,
    MovementScannerUnload,
    StandardDialog,
    ManualBarcodeInput,
    FileUploader,
    MovementUnloadStepper,
    ErrorDialog,
    ConfirmDialog,
    FullScreenDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    canContinue() {
      return this.fileToUnload === undefined;
    },
    canElaborate() {
      return this.itemsSelectedToUnload.length > 0;
    },
    canElaborateByScanner() {
      return (
        this.itemsSelectedToUnload.length > 0 &&
        this.itemsSelectedToUnload.filter(
          (item) =>
            Number(item.qtyToUnload) <= Number(item.qtyInStock) &&
            Number(item.qtyToUnload) > 0
        ).length == this.itemsSelectedToUnload.length
      );
    },
    selectedComponent() {
      if (!!this.selectedType) {
        this.selectedCategory = this.selectedType.name.toLowerCase();
        return this.selectedType.component;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.localOpenDialog = this.openDialog;
    this.setUser();
  },

  data: function () {
    return {
      elaborating: false,

      showErrorDialog: false,
      errorMessage: "",
      successMessage: "",
      canSave: false,
      localOpenDialog: undefined,
      userId: undefined,
      fileToUnload: undefined,
      stepFocused: 1,
      itemsFromFile: [],
      itemsSelectedToUnload: [],
      itemsUnloaded: [],
      itemsInError: [],
      counter: 0,
      selectedType: undefined,
      openConfirmDialog: false,
      selectedCategory: "",
      barcodeInputKeyboard: undefined,
      bus: new Vue(),
      typeUpload: [
        {
          name: "Scannerizza Prodotti",
          color: "#8a77e2",

          icon: "mdi-barcode-scan",
          component: "scanner",
        },
/*         {
          name: "Carica File",
          color: "#8a77e2",

          icon: "mdi-file-import",
          component: "unloader",
        }, */
      ],
      barcodePermission: undefined,
      showBarcode: false,
      tempSelectedType: undefined,
      movementsLoading: false,
    };
  },
  methods: {
    uploadPermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Warehouse", "manage").then((result) => {
        if (result) {
          this.selectedType = this.tempSelectedType
        } else {
          this.selectedType = undefined
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handlePermission(selectedType) {
      this.barcodePermission = undefined
      this.showBarcode = true
      this.tempSelectedType = selectedType
    },
    setUser() {
      currentUser.getUser().then((user) => {
        this.userId = user.id;
      });
    },
    toUpperCaseFirstLetter(string) {
      return string.substring(0, 1).toUpperCase() + string.substring(1);
    },
    backSelection() {
      this.selectedCategory = "";
      this.selectedType = undefined;
    },
    handleBack(step) {
      this.stepFocused = step;
    },
    close: function () {
      this.$emit("close");
    },
    goToItemList: function () {
      this.$router.push({
        name: "MovementItemList",
        props: {
          openDialog: true,
        },
      });
    },
    handleSelectionFile(file) {
      this.fileToUnload = file;
    },
    handleSelectionMultipleItem(itemsSelected) {
      this.itemsSelectedToUnload = itemsSelected;
      this.counter = itemsSelected.length;
    },
    continueToUnload() {
      let _builder = importerService._buildItemObjectFromFile;

      importerService
        ._readFile(this.fileToUnload, _builder, false)
        .then((_itemsToUnload) => {
          this.itemsFromFile = _itemsToUnload;
          this.stepFocused = 2;
        });
    },
    unloadFromFile() {
      this.elaborating = true;
      this.movementsLoading = true;

      let fileInfo = {
        name: this.fileToUnload.name,
        extension: this.fileToUnload.name.split(".")[1],
      };
      let itemsToUnload = [];
      this.itemsSelectedToUnload.map((item) => {
        const { key, ...newItem } = item;
        itemsToUnload.push(newItem);
      });

      importerService
        .unloadFromFile(fileInfo, itemsToUnload, "items", this.userId)
        .then((itemsElaborated) => {
          this.itemsUnloaded = itemsElaborated.rowsImported;
          this.itemsInError = itemsElaborated.rowsInError;
          
          this.resetAfterElaborate();
          this.movementsLoading = false;
          this.elaborating = false;
        })
        .catch((itemsElaborated) => {
          this.itemsUnloaded = itemsElaborated.rowsImported;
          this.itemsInError = itemsElaborated.rowsInError;
          this.resetAfterElaborate();
          this.movementsLoading = false;
          this.elaborating = false;
        });
    },
    unloadFromScanner() {
      this.movementsLoading = true;

      let itemsToUnload = [];
      this.itemsSelectedToUnload.map((item) => {
        const { key, ...newItem } = item;
        itemsToUnload.push(newItem);
      });
      movementsService
        .unloadFromScanner(itemsToUnload, this.userId)
        .then(() => {
          this.successMessage = itemVocabulary.Unload_Complete.desc;
          this.openConfirmDialog = true;
          this.resetAfterElaborate();
          this.movementsLoading = false;
        })
        .catch((itemsElaborated) => {
          // this.itemsUnloaded = itemsElaborated.rowsImported;
          // this.itemsInError = itemsElaborated.rowsInError;
          this.resetAfterElaborate();
          this.movementsLoading = false;
        });
    },
    backElaborate() {
      this.stepFocused = this.stepFocused - 1;
      this.counter = 0;
      this.itemsSelectedToUnload = [];
      this.itemsUnloaded = [];
      this.itemsInError = [];
    },
    resetAfterElaborate() {
      this.counter = 0;
      this.itemsSelectedToUnload = [];
      this.stepFocused = 3;
    },
    closeAll() {
      this.openConfirmDialog = false;
      this.localOpenDialog = false;
    },
    sendBarcodeInputKeyboard() {
      this.bus.$emit("input-keyboard", this.barcodeInputKeyboard);
    },
  },
};
</script>

<style>
.section-fade-in {
  animation: smooth 0.8s ease-in;
}

@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>