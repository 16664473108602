<template>
  <div>
    <v-alert dense outlined type="success">
      <div class="font-italic font-weight-light">
        Prodotti scaricati({{ countItemsUnloaded }})
      </div></v-alert
    >
    <TypeDataTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="false"
      item-key="key"
      fixed-header
      class="mt-1"
      :items="localItemsUnloaded"
      :headers="headers"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :show-actions="false"
      loading-text="Caricamento prodotti importati ..."
      :translator="translator"
      :loading="loading"
    >
      <template v-slot:custom-qtyInStock="{ item }">
        <v-chip color="success">
          {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
        >
      </template>
    </TypeDataTable>
    <v-alert color="red" dense outlined type="error">
      <div class="font-italic font-weight-light">
        Prodotti andati in errore ({{ countItemsInError }})
      </div>
    </v-alert>
    <TypeDataTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="false"
      item-key="key"
      fixed-header
      class="mt-1"
      :items="localItemsInError"
      :headers="headersInError"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :show-actions="false"
      :translator="translator"
      loading-text="Caricamento prodotti in errore ..."
      :loading="loading"
    >
      <template v-slot:custom-qtyToUnload="{ item }">
        <v-chip color="error">
          {{ item["qtyToUnload"] ? item["qtyToUnload"] : 0 }}</v-chip
        >
      </template></TypeDataTable
    >
  </div>
</template>

<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
export default {
  name: "ElaboratedItemList",
  components: {
    TypeDataTable,
  },
  props: {
    itemsInError: {
      type: Array,
      default: function () {
        return [];
      },
    },
    itemsUnloaded: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      rowPerPage: 20,
      totalPages: 1,
      dataTableHeightValue: 160,
      localItemsUnloaded: [],
      localItemsInError: [],
      countItemsUnloaded: 0,
      countItemsInError: 0,
      headers: [
        {
          text: "Codice Interno",
          value: "internalCode",
        },
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Qunaità in Giacenza aggiornata",
          value: "qtyInStock",
          type: "custom",
        },
      ],
      headersInError: [
        {
          text: "Errore",
          value: "error",
        },
        {
          text: "Barcode",
          value: "code",
        },

        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Quantità da scaricare",
          value: "qtyToUnload",
          type: "custom",
        },
      ],
    };
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.countItemsUnloaded = this.itemsUnloaded.length;
      this.countItemsInError = this.itemsInError.length;
      this.localItemsUnloaded = this.itemsUnloaded;
      this.localItemsInError = this.itemsInError;
      this.loading = false;
    }, 1000);
  },
  methods: {
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
  },
};
</script>

<style>
</style>