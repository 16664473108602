<template>
  <div class="mt-4">
    <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
      <TypeDataTable
        v-model="selected"
        loading-text="Scannerizza prodotti"
        :headers="headers"
        :loading="loading"
        :items="itemsScanned"
        itemKey="code"
        :show-actions="false"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        :height="dataTableHeight"
        :show-select="true"
        :single-select="false"
        :translator="translator"
        @input="handleSelection"
      >
        <template v-slot:custom-qtyToUnload="{ item }">
          <v-text-field
            type="number"
            @click.stop=""
            dense
            filled
            :rules="[qtyCheck]"
            hide-details="auto"
            not-specified-text="0"
            v-model="item['qtyToUnload']"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip v-if="item['qtyInStock'] > 0" color="success">
            {{ item["qtyInStock"] }}</v-chip
          >
          <v-chip v-else color="error">
            {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
          >
        </template>
      </TypeDataTable>
    </div>

    <ErrorDialog
      dialog-width="460"
      dialog-height="calc(360-10px)"
      persistent
      v-model="showErrorDialog"
      :title="titleError"
    >
      <v-alert class="ma-2" dense outlined type="error">
        {{ errorMessage }}
      </v-alert>
    </ErrorDialog>
  </div>
</template>

<script>
import barcodeService from "@/services/barcode/barcode.service.js";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import itemService from "@/services/warehouse/items.service.js";
import ErrorDialog from "@/components/common/StandardDialog";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import Vue from 'vue';
export default {
  components: {
    TypeDataTable,
    ErrorDialog,
  },
  data() {
    return {
      showErrorDialog: false,
      errorMessage: "",
      titleError: "",
      selected: undefined,
      loading: false,
      dataTableHeightValue: 400,
      customers: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 1,
      resizeListener: undefined,
      itemsScanned: [],
      itemsSelectedToUnload: [],
      barcode: "",
      titleCreateItemNotPresent: "Creazione prodotto non presente a magazzino",
      qtyCheck: (qty) => {
        if (qty > 0 && this.isValid()) {
          return true;
        } else {
          return itemVocabulary.Correct_Qty.desc;
        }
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function(){
        return new Vue()
      },
    },
  },
  mounted() {
    this.bus.$on('input-keyboard',this.handleInputKeyboard)
    this.fetchHeaders();
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  methods: {
    isValid() {
      return this.itemsScanned.every(
        (item) =>
          Number(item.qtyToUnload) > 0 &&
          Number(item.qtyInStock) >= Number(item.qtyToUnload)
      );
    },
    getItem(barcode) {
      barcodeService.getByCode("items", barcode).then((results) => {
        if (!results.entity) {
          this.errorMessage = itemVocabulary.ItemNotPresent.desc;
          this.showErrorDialog = true;
        } else {
          if (!results.entity.qtyInStock || results.entity.qtyInStock == 0) {
            this.errorMessage = itemVocabulary.Unload_QtyEqualZero.desc;
            this.showErrorDialog = true;
            this.titleError =
              "Errore " +
              results.entity.description +
              "  (" +
              results.entity.code +
              ")";
          } else {
            itemService
              .get(results.entity.id)
              .then((results) => {
                results.code = barcode
                results["qtyToUnload"] = 1;
                if (this.checkCodeAlreadyScanned(results)) return;
                else this.itemsScanned.push(results);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    },
    checkCodeAlreadyScanned(itemScanned) {
      let itemAlreadyScanned = this.itemsScanned.find(
        (item) => item.code == itemScanned.code
      );
      if (itemAlreadyScanned) {
        itemAlreadyScanned.qtyToUnload++;
        return itemAlreadyScanned;
      } else return;
    },
    fetchHeaders() {
      itemService._fieldsToUnload().then((headers) => {
        this.headers = headers;
      });
    },
    translator(field, value) {
      
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleSelection(itemsSelected) {
      this.itemsSelectedToUnload = itemsSelected;
      this.$emit("selection-items-to-unload", itemsSelected);
    },
     handleInputKeyboard(barcode){
      this.barcode = barcode
      this.getItem(barcode)
    },
  },
  watch: {
    itemsScanned(newVal) {
      this.itemsScanned = newVal;
    },
  },
  barcode: {
    scan(barcode) {
      this.barcode = barcode;
      this.getItem(barcode);
    },
  },
};
</script>

<style>
</style>